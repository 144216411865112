.pricing-wrapper {
  min-height: 50vh;
  /* padding-top: 2rem; */
}

.pricing-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: auto;
  padding-top: 2rem;
}

.pricing-page-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: auto;
  font-family: 'semi-bold';
  text-align: center;
  /* padding: 3rem 0rem; */
  padding-top: 2rem;
}

.pricing-page-about h2 {
  font-family: 'bold-italic';
  padding-bottom: .5rem;
}

.pricing-page-about div {
  color: #9f9f9f;
  font-family: 'medium-italic';
  /* padding-bottom: 2rem; */
}

.pricing-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, .1);
  width: 80%;
  margin: auto;
  padding-top: 1rem;
}

@media screen and (max-width: 768px) {
  .pricing-list {
    justify-content: center;
    margin: auto;
  }

  .pricing-page-about {
    padding: 1rem 0rem;
  }

  .pricing-page-about {
    width: 80%;
  }
}