.trainer-wrapper {
  width: 16rem;
  height: 30.30rem;
  font-size: 80%;
  padding: 1rem;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease;
  margin: 1rem 0rem;
  position: relative; /* Add this line */
  display: flex; /* Add this line */
  flex-direction: column; /* Add this line */
}
.learn-more-btn {
  margin-top: auto; /* Pushes the button to the bottom */
  align-self: center; /* Centers the button horizontally */
  padding: 0.5rem 1rem; /* Adjust padding to your liking */
  background-color: #ff4040; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Removes border */
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 1rem;
  width: 100%;
}
/*   
  color: white;
  border: none;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease; */

.trainer-wrapper:hover {
  transform: translateY(-5px);
}

.trainer-img {
  width: 14rem;
  padding-top: 0rem;
  padding-bottom: .5rem;
  filter: grayscale(100%);
  transition: filter 0.4s ease;
  display: block; /* Make the image a block element */
  margin: 0 auto; /* Center the image */
}

.trainer-wrapper:hover .trainer-img {
  filter: grayscale(0);
}

.trainer-name {
  text-align: left;
  padding-left: 0rem;
  font-size: 1.2rem;
}

.trainer-pros {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: .5rem;
  padding-left: .5rem;
}

.trainer-pros span {
  font-size: 75%;
  outline: 2px solid red;
  color: red;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  filter: grayscale(100%);
  transition: filter 0.4s ease;
}

.trainer-wrapper:hover .trainer-pros span {
  filter: grayscale(0);
}

.trainer-bio {
  padding-top: .25rem;
  /* padding-left: 0.5rem; */
  /* padding-right: 0.5rem; */
  display: flex;
  flex-direction: row;
  text-align: justify;
}

.faded {
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}