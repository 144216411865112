.news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 5rem;
  padding-left: 0;
  padding-right: 0;
  opacity: 0; /* Initially set opacity to 0 */
  animation: fadeIn .3s ease-in-out forwards; /* Apply the fade-in animation */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.news-card-title {
  text-decoration: none;
  color: black;
}
a {
  text-decoration: none;
}

.news-loader {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.news-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1019px) {
  .news {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 768px) {
  .news {
    /* height: 100vh; */
  }
}

@media screen and (max-width: 768px) {
  .news-wrapper {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) and (max-width: 1019px) {
  .news {
    padding-top: 8rem;
  }
}