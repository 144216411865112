.gallery-wrapper {
    min-height: 30vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 6rem; */
    padding-bottom: 2.4rem;
    /* background-color: rgb(36, 36, 36); */
    width: 100vw;
  }
  .gallery-section {
    margin-bottom: 2rem;
  }
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 0.5rem;
  }
  .gallery-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.25rem;
    transition: transform 0.3s ease;
  }
  .gallery-item:hover img {
    transform: scale(1.05);
  }
  