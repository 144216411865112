@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 2rem;
  background-color: #f2f2f2;
  z-index: 20;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  right: 0;
  color: #121212;
  animation: slideDown 0.5s ease-out;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
}

.mobile-logo {
  display: none;
  width: 50px;
  height: 50px;
}

.desktop-logo {
  display: block;
  width: 6rem;
}

.nav-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  color: #f2f2f2;
}

.nav-links a,
.programi {
  text-decoration: none;
  font-weight: 600;
  font-size: 100%;
  color: #333333;
  transition: background-color 0.3s ease;
  padding: 6px;
  cursor: pointer;
}

.programs-link:hover {
  background-color: #f2f2f2 !important;
}

.nav-links a:hover,
.programi:hover {
  background-color: lightgrey;
}

.nav-links.mobile-nav-links {
  display: none;
}

.nav-links-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.menu-icon {
  display: none;
}

.nav-button button {
  background-color: #237E52;
  font-family: 'bold-italic';
  text-transform: uppercase;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0;
  font-size: 12px;
  cursor: pointer;
}

.nav-button button:hover {
  background-color: #196b44;
  color: #f2f2f2;
}

.dropdown-content {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #f2f2f2;
  width: 10rem;
  z-index: 1001;
  align-items: flex-start;
  padding-left: 1rem;
}

.dropdown-content {}

.dropdown-content a {
  padding: 0.5rem 0;
}

.dropdown:hover .dropdown-content {
  margin-top: .45rem;
  display: flex;
}
.button-appear {
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.button-appear.visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}


@keyframes button-appear-animation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}
@media screen and (max-width: 768px) {

  .nav-links a,
  .programi {
    text-decoration: none;
    font-weight: 600;
    font-size: 125%;
    color: #333333;
    transition: background-color 0.3s ease;
    padding: 6px;
    cursor: pointer;
  }

  .mobile-logo {
    display: block;
  }

  .desktop-logo {
    display: none;
  }

  .logo-container {
    width: 100%;
  }

  .nav-links {
    display: none;
  }

  .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #121212;
  }

  .nav-links.mobile-nav-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transform: translateY(-100%);
    animation: slideDown 0.3s ease-out forwards;
  }

  .nav-links-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .nav-links a {
    font-size: 125%;
  }

  .menu-icon {
    display: block;
  }

  .nav-button {
    display: none;
  }

  .dropdown-content {
    position: relative;
    width: auto;
    padding-left: 0;
  }

  .dropdown-content.hidden {
    display: none !important;
  }

  .dropdown-content a {
    padding: 0.25rem 0;
  }
}
