.pricing-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 21rem;
  margin: auto;
  margin-bottom: 2rem;
}
.pricing-card-wrapper img {
  width: 21rem;
  height: 12rem;
  padding-bottom: 1.5rem;
}

.pricing-card-price {
  font-size: 1.8rem;
  font-weight: 600;
}

.pricing-card-title {
  font-size: 1.10rem;
  margin-top: .65rem;
  margin-bottom: 1rem;
  padding: 0rem 0rem;
}

.pricing-card-sub-title {
  font-size: .9rem;
}

.pricing-card-pros {
  font-size: 1.25rem;
  color: lightgray;
  display: flex;
  flex-direction: column;
}

.pricing-card-pro {
  font-size: .75rem;
  color: #969696;
}

.pricing-card-button {
  font-family: 'bold-italic';
  position: relative;
  display: inline-block;
  padding: 15px 35px;
  background-color: #237E52;
  color: #f2f2f2;
  border: none;
  cursor: pointer;
  transition: padding-right 0.4s;
  overflow: hidden;
  text-align: center;
  font-size: 16px;
}

.pricing-card-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #f2f2f2;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  transition: left 0.4s, opacity 0.2s;
  opacity: 0;
  pointer-events: none;
}

.pricing-card-button:hover::before {
  left: calc(100% - 20px);
  opacity: 1;
  pointer-events: auto;
}

.pricing-card-button:hover {
  padding-right: 40px;
}


@media screen and (max-width: 768px) {
  .pricing-card-wrapper img {
    width: 17rem;
    height: 10rem;
    padding-bottom: 1.5rem;
  }
}
@keyframes cardFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pricing-card-animate {
  animation: 2s ease-out 0s 1 cardFadeIn;
}

.green {
  background-color: rgba(30, 217, 36, 0.366) !important;
}
.green-plus {
  background-color: rgba(2, 213, 9, 0.602) !important;
}
.red {
  background-color: rgb(255, 40, 40);
}
.gold {
  background-color: rgb(228, 205, 70);
}
.black {
  background-color: black;
  color: #f2f2f2 !important;
}
.gray {
  background-color: rgb(154, 154, 154);
}
.pink {
  background-color: rgba(255, 210, 217, 0.732) !important;
}
.pink-plus {
  background-color: rgb(255, 165, 180) !important;
}
.blue {
  background-color: rgba(196, 240, 255, 0.732) !important;
}
.blue-plus {
  background-color: rgba(105, 217, 255, 0.732) !important;
}
