@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'bold-italic';
  src: url('./app/assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'medium-italic';
  src: url('./app/assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'semi-bold';
  src: url('./app/assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'semi-bold-italic';
  src: url('./app/assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'anton';
  src: url('./app/assets/fonts/Anton-Regular.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: 'semi-bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
