.trainers-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 60%;
  margin: auto;
  justify-content: space-between;
  padding-top: 1rem;
}

@media screen and (max-width: 768px) {
  .trainers-container {
    justify-content: center;
  }
}
@media screen and (max-width: 1024px) {
  .trainers-container {
    width: 80%;
  }
}
