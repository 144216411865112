.single-news-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 1rem;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 4rem;
}

.single-news-img {
  width: 44rem;
  max-width: 80%;
  max-height: 80%;
}

.single-news-content {
  width: 44rem;
  max-width: 80%;
  text-align: justify;
  margin-left: 1rem;
}

.single-news-btn {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center; /* Reset alignment to center for mobile */
  }
  
  .single-news-img,
  .single-news-content {
    width: 90%;
    max-width: none;
  }
  
  .single-news-content {
    font-size: .9rem;
    margin-left: 0;
  }
}
