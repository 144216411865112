* {
  letter-spacing: .025rem;
}

.slick-dots li.slick-active button:before {
  color: #f2f2f2 !important;
}

.slick-dots li button:before {
  color: #f2f2f2 !important;
}

.text-white {
  color: #f2f2f2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-main {
  background-color: #f2f2f2;
  color: 22181C;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: .75rem 1.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.4s ease;
}

.btn-main:hover {
  background-color: #237E52;
  color: #f2f2f2;
  transform: translateY(-2px);
}

.bg-main {
  background-color: #237E52;
}

.adv-bg {
  position: relative;
  /* Make sure the element has a positioning context */
  /* background-image: url('https://media1.popsugar-assets.com/files/thumbor/GVbdKVQEgCS-Xz1FmNVKp3qzHpE/571x0:2571x2000/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/07/07/483/n/38922805/cb502fb65f044ff906a1f0.62957338_/i/Full-Body-Gym-Workout-Women.jpg'); */
  /* background-image: url('/src/app/assets/banner-pics/other/girl-on-treadmil-square.webp'); */
  background-image: url('/src/app/assets/banner-pics/other/treadmill_square.webp');
  background-position: center;
  background-blend-mode: normal;
  background-size: cover;
}

.adv-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 102, 15, 0.645);
  /* Adjust the color and opacity as needed */
  z-index: 1;
  /* Ensure the overlay is above the background image */
}

.temporary-bg {
  background-image: url('app/assets/pngs/banner.webp');
  height: 100vh;
}

.grecaptcha-badge {
  position: relative; /* or 'absolute' or 'fixed' depending on your layout */
  z-index: 9999; /* A high value to ensure it's on top of everything else */
}

.special-offer-bg{
  background-image: url('../src/app/assets/svgs/special-bg.svg');
}

.forced-italic {
  font-style: normal; /* Ensure the font doesn't conflict with skew */
  transform: skewX(-10deg); /* Adjust the degree as needed */
}