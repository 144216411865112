.program-page-gallery {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 2rem;
}

.program-page-gallery img {
  max-width: 80%;
  margin-bottom: 2rem;
  width: 14rem;
  height: 10rem;
  background-color: black;
}

.program-page-wrapper {
  /* height: 135vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.program-page-banner {
  width: 100%;
  height: 34vh;
  padding-top: 4rem;
  text-align: center;
  font-size: 2.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .25)
}

.program-tips {
  color: #f2f2f2;
  background-color: #237E52;
  font-size: .75rem;
  height: 2.5rem !important;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem;
}

.program-tips .dot {
  margin: 0rem 1.5rem;
}

.program-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  max-width: 100%;
}

.program-page-img {
  width: 20rem;
  max-width: 100%;
  margin-right: 4rem;
}

.program-page-desc {
  width: 100%;
  max-width: 32rem;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .program-page-banner {
    padding-top: 0rem;
    font-size: 2rem;
  }

  .program-page-gallery {
    height: 80%;
    width: 80%;
  }

  .program-tips {
    font-size: 0.75rem;
    flex-direction: column;
    height: 3.5rem !important;
    justify-content: space-between;
    padding: 1rem 0rem;
  }

  .program-tips .dot {
    display: none;
  }

  .program-page-gallery img {
    max-width: 80%;
    width: 10rem;
    height: 6rem;
  }

  .program-page-wrapper {
    padding-top: 4rem;
    min-height: 180vh;
    padding: none;
  }

  .program-page-title {
    margin-top: 8rem;
    margin-bottom: 2rem;
  }

  .program-page-content {
    justify-content: space-between;
    margin-top: 0rem;
  }

  .program-page-img {
    width: 24rem;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }

  .program-page-desc {
    max-width: 24rem;
  }
}

/* Desktop Styles */
@media screen and (min-width: 768px) {
  .program-page-wrapper {
    justify-content: flex-start;
  }

  .program-page-banner h2 {
    font-size: 2.5rem;
  }

  .program-page-tips {
    padding: 1rem 0rem;
  }

  .program-page-title {
    margin-top: 0rem;
    margin-bottom: 4rem;
  }

  .program-page-content {
    padding-top: 1rem;
  }

  .program-page-img {
    width: 28rem;
    margin-right: 8rem;
  }

  .program-page-desc {
    width: 32rem;
    font-size: 1rem;
  }

  .program-page-gallery {
    padding: 4rem 0rem;
  }
}

/* Styles for large screens (optional) */
@media screen and (min-width: 1400px) {
  /* Add your styles for large screens here */
}

/* Styles for extra small screens (optional) */
@media screen and (max-width: 430px) {
  .program-page-wrapper {
    /* height: 140vh; */
    padding-top: 4rem;
  }
  .program-page-gallery {
    height: 80%;
    width: 80%;
    margin: auto;
  }

  .program-page-gallery img {
    max-width: 100%;
    margin: auto;
    margin-bottom: 3rem;
    width: 20rem;
    height: 10rem;
    background-color: black;
  }

  .program-page-desc {
    font-size: 0.85rem;
    width: 90%;
  }
}
