.adults-wrapper {
  min-height: 30vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  color: #f2f2f2;
  background-color: rgb(36, 36, 36);
  width: 100vw;
}
.adults-hero {
  height: 75vh;
  width: 100vw;
  background-image: url('https://assets.gqindia.com/photos/5d384e186552a20008906dad/16:9/pass/Best%20Kickboxing%20and%20MMA%20classes%20in%20Mumbai.jpg');
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .adults-wrapper {
    padding-top: 1.5rem;
  }
  .adults-hero {
    height: 85vh;
    width: 100vw;
    background-image: url('https://assets.gqindia.com/photos/5d384e186552a20008906dad/16:9/pass/Best%20Kickboxing%20and%20MMA%20classes%20in%20Mumbai.jpg');
    background-position-x: -52rem;
    background-size: cover;
  }
}
