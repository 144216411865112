.footer {
  background-color: #237E52;
  color: white !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: .025rem;
  padding: 20px;
}

.footer .logo-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .footer-logo {
  width: 22rem;
  max-width: 80%;
  height: auto;
  background-color: 22181C;
}

.footer .footer-button {
  background-color: white;
  border: none;
  padding: 10px;
  color: 22181C;
  margin-bottom: 20px;
  cursor: pointer;
}

.footer .social-icons {
  text-align: center;
}

.footer .social-icons h4 {
  margin-bottom: 10px;
  padding-top: 1rem;
  font-weight: 500;
}

.footer .social-icons .icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 350px;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.footer .social-icons a {
  font-size: 140%;
  color: white;
}
.footer .social-icons a :hover {
  transition: color 0.2s ease;
  color: #94ffcb;
}

.footer-info {
  text-align: center;
}

.footer .footer-info h4 {
  margin-bottom: 5px;
}

.footer .footer-info p {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
  }

  .footer .logo-section {
  }

  .footer .social-icons {
    width: 12rem;
    margin: auto;
  }

  .footer .footer-info {
    text-align: left;
  }
}
