.contact-wrapper {
  /* padding-top: 6rem; */
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
}

.contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 90%;
  margin: auto;
  padding: none;
}

.contact-form-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.contact-form-section form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* max-width: 600px; */
  width: 600px;
}

.contact-form-input,
.contact-form-submit {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  margin: 10px 0;
  border: none;
  border-radius: 0;
  background-color: #f1f1f1;
  transition: background-color 0.3s;
  font-size: 14px;

  font-family: 'bold-italic';
}

.contact-form-input:hover {
  background-color: #e1e1e1;
}

.contact-form-submit {
  background-color: #237E52;
  font-family: 'bold-italic';
  color: white;
  cursor: pointer;
}

.contact-form-submit :hover {
  background-color: #196b44;
}

.contact-image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form-image {
  max-width: 80%;
  height: auto;
}

.contact-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 5rem; */
  margin: auto;
  width: 100%;
}

.contact-info {
  display: flex;
  flex-direction: column;
  width: 18rem;
  padding: 1rem;
}

.contact-info-title {
  font-size: 1.4rem;
  font-weight: 600;
}

.contact-info-content {
  padding-left: 1.6rem;
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 640px) {
  .contact-info-wrapper {
    /* padding-top: 3rem; */
  }
}

@media (max-width: 1090px) {
  .contact-container {
    min-height: 150vh;
    flex-direction: column;
    padding-top: 3rem;
  }
}

@media (max-width: 768px) {
  .contact-wrapper {
    padding-bottom: 2rem;
    min-height: 150vh;
  }

  .contact-form-section form {
    align-items: center;
  }

  .contact-info-wrapper {}

  .contact-container {
    flex-direction: column;
    padding-top: 14rem;
    padding: 20px;
    align-items: center;
    /* Center the content vertically */
    justify-content: center;
    height: 100vh;
  }

  .contact-form-section {
    margin-bottom: 20px;
    padding-top: 2rem;
    width: 100%;
  }

  .contact-form-input,
  .contact-form-submit {
    max-width: 90%;
    align-self: center;
  }

  .contact-form-image {
    display: none;
    max-width: 100%;
    width: 80%;
  }
}

@media (max-width: 360px) {
  .contact-container {
    padding: 30px;
  }

  .contact-form-input,
  .contact-form-submit {
    margin: 15px auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 90%;
  }
}

.text-alert {
  color: red;
}
.error-container {
  height: 20px;
}

.contact-form-submit {
  text-align: center;
}

.contact-form-submit svg {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
