.back-to-top {
  position: fixed;
  z-index: 1000000;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.back-to-top.visible {
  opacity: 1;
}
