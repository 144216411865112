.signup-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.signup-popup {
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 5px;
  animation: fade-in 0.5s;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.form-container {
  display: flex;
  flex-direction: column;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
