.slick-arrow.slick-next::before {
  color: #f2f2f2 !important;
  left: 3% !important;
  z-index: 1;
}

.slick-arrow.slick-prev::before {
  color: #f2f2f2 !important;
  left: 3% !important;
  z-index: 1;
}

.program-showcase {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 4rem;
}

.program-showcase-title {
  color: #f2f2f2;
  text-align: center;
  font-family: 'bold-italic';
  font-size: 20px;
  margin-bottom: 20px;
}

.program-list {
  margin: auto;
}

@media (max-width: 768px) {
  .program-list {
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .slick-arrow.slick-next::before {
    display: none;
  }

  .slick-arrow.slick-prev::before {
    display: none;
  }
}