/* @keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.card-animate {
  animation: .7s ease-out 0s 1 slideInFromLeft;
} */

.card {
  background-color: #fafafa;
  border-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
  width: 300px;
  margin: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.card:hover,
.card:focus,
.card:active {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  filter: brightness(120%);
}

.card-image {
  width: 250px;
  /* Update the desired width */
  height: 150px;
  /* Update the desired height */
  border-radius: 0;
  margin-bottom: 10px;
}

.card-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-description {
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
}

.learn-more-button {
  font-family: 'bold-italic';
  text-transform: uppercase;
  background-color: #237E52;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .card {
    width: 250px;
    /* Update the desired width for mobile */
    margin: auto;
    /* margin: 10px; */
  }

  .card-image {
    width: 200px;
    /* Update the desired width for mobile */
    height: 120px;
    /* Update the desired height for mobile */
  }
}