.team-wrapper {
  min-height: 30vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 6rem; */
  padding-bottom: 2.4rem;
  /* background-color: rgb(36, 36, 36); */
  width: 100vw;
}