.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.popup-content {
  position: relative;
  width: 80%; /* Adjust the width as needed */
  max-width: 500px; /* Add a maximum width for larger screens */
  height: auto; /* Remove fixed height */
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2.popup-name {
  margin: 0;
  text-align: center;
  flex-grow: 1;
}

.popup-close-btn {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.popup-body {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.image-container {
  flex-grow: 1; /* Take up remaining vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-body img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: none;
}

.popup-body img.loaded {
  display: block;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.learn-more-button {
  padding: 10px 20px;
}
