.news-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px; /* Adjust the desired fixed width */
    height: 300px;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px;
    transition: 0.3s;
    overflow: hidden;
    cursor: pointer;
  }
  
  .news-card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .news-card-image-container {
    width: 100%;
    height: 180px; /* Adjust the desired height for the image */
    overflow: hidden;
  }
  
  .news-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .news-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;
  }
  
  .news-card-title {
    font-size: 18px; /* Adjust the font size as needed */
    margin-bottom: 10px;
    color: lightgray;
  }
  
  .news-card-description {
    font-size: 14px; /* Adjust the font size as needed */
    color: lightgray;
    margin-bottom: 10px;
  }
  
  .news-card-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .news-card-button {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .news-card-button:hover {
    color: #000;
  }
  