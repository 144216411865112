.mt-10 {
  margin-top: 1rem;
}
.hero-wrapper {
  padding-top: 3.5rem;
  background-image: url('https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80');
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.parallax-hero-content {
  height: 100vh;
  width: 100vw;
  background-image: url('/src/app/assets/banner-pics/2.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.advantages-img {
  width: 50%;
  height: 50%;
}

.advantages-img img {
  max-width: 1400px;
  max-height: 400px;
  width: 100%;
  background: 22181C;
  transform: translate(6rem, 6rem);
}

.advantages-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 4rem;
  width: 55%;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.advantage-pros {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
  
  padding: 0rem 4rem;
}

.advantages-pro {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
}

.advantages-pro div:first-child {
  margin-right: 1rem;
}

@media screen and (max-width: 768px) {
  .hero-wrapper {
    padding-top: 3.5rem;
    background-image: url('https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80');
    height: 50vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .hero-content {
    font-size: .8rem;
    color: black;
    font-weight: 600;
  }
  .hero-content img {
    /* transform: translate(0, -8rem); */
    width: 18rem;
  }

  .advantages-img {
    width: auto;
    height: auto;
  }

  .advantages-img img {
    transform: none;
    padding-top: 1rem;
    width: 90vw;
    margin: auto;
  }

  .advantages-content {
    padding: 0rem;
    width: 100%;
    align-items: center;
  }

  .advantages-pros {
  }

  .advantages-pro {
  }
}
