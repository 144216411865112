.fighting-wrapper {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.fighting-tab-select {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 22rem;
  padding-top: 1rem;
}
.btn-tab {
  font-family: 'bold-italic';
  font-size: 1rem;
  color: #f2f2f2;
  outline: #f2f2f2 2px solid;
  padding: .75rem 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.active-tab {
  background-color: #E70034;
  color: #f2f2f2;
  outline: none;
  cursor: default;
}
.fighting-width {
  width: 100vw;
  background-color: rgb(36, 36, 36);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: .25rem;
}

@media screen and (max-width: 768px) {
  .fighting-tab-select {
    width: 80%;
  }
  
}