.payment-option {
  width: 16rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.payment-special-bg-basic{
  background-image: url('../../../assets/svgs/special-bg-basic.svg');
}

.payment-special-bg-premium{
  background-image: url('../../../assets/svgs/special-bg-premium.svg');
}

.payment-option-header,
.payment-option-price,
.payment-option-button,
.payment-option-footnote {
  flex-shrink: 0;
}

.payment-option-features {
  flex-grow: 1;
  overflow: auto;
  background-color: #fff;
  list-style: none;
  margin: 0;
  padding: 1rem;
  font-size: 0.9rem;
}

.payment-option-features li {
  margin-bottom: 0.5rem;
}

.payment-option-header {
  background-color: #f5f5f5;
  color: #333;
  padding: .8rem;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}

.payment-option-price {
  font-size: 2.5rem;
  text-align: center;
  padding: 1rem;
  background-color: #fff;
  color: #333;
}

.payment-option-period {
  font-size: 1rem;
  color: #666;
}
.payment-option-extra {
  font-size: .8rem;
  color: #666;
}

.payment-option-button {
  background-color: #ff4040;
  color: white;
  border: none;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.payment-option-button:hover {
  background-color: #ff2020;
}

.payment-option-footnote {
  background-color: #f5f5f5;
  color: #666;
  padding: 1rem;
  font-size: 0.8rem;
  text-align: center;
}

.payment-option-image {
  display: block;
  width: 100%;
  height: 10rem;
  object-fit: cover;
}

.payment-dialog {
  width: 50%;
  /* max-width: 30rem; */
  padding: 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: fadeIn 0.3s ease forwards;
  opacity: 0;
}

.payment-dialog.open {
  opacity: 1;
}

.payment-dialog-header {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

.payment-dialog-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.payment-dialog-option {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-top: 1px solid #eaeaea;
}

.payment-dialog-option:last-child {
  border-bottom: 1px solid #eaeaea;
}

.payment-dialog-label {
  font-weight: bold;
}

.payment-dialog-price {
  font-size: 1rem;
  color: #333;
}

.payment-dialog-close-button {
  padding: 0.5rem 1rem;
  background-color: #ff4040;
  color: white;
  border: none;
  cursor: pointer;
  align-self: center;
  margin-top: 1rem;
  width: 100%;
}

.payment-dialog-close-button:hover {
  background-color: #e63030;
}

.payment-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  z-index: 999;
  animation: fadeIn 0.3s ease forwards;
  opacity: 0;
}

.payment-backdrop.open {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .payment-option {
    width: 100%;
    /* margin-bottom: 3rem; */
  }

  .payment-option-image {
    height: 10rem;
  }

  .payment-dialog {
    width: 95%;
    padding: 1rem;
  }

  .payment-dialog-header {
    font-size: 1rem;
  }

  .payment-dialog-options {
    font-size: 0.9rem;
  }

  .payment-dialog-close-button {
    padding: 0.4rem 0.8rem;
  }
}

@media screen and (max-width: 526px) {
  .payment-dialog {
    max-width: 80%;
  }

  .payment-option-image {
    height: 10rem;
  }
  .payment-dialog-header {
    font-size: .9rem;
  }

  .payment-dialog-options {
    font-size: 0.7rem;
  }
  .payment-dialog-close-button {
    font-size: .9rem;
  }
}
