.therapy-wrapper {
  min-height: 100vh;
}

.therapy-plans {
  display: flex;
  justify-content: space-between;
  width: 42rem;
  margin: auto;
}

.therapy-plan {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  text-align: center;
  padding: 1.5rem 2.5rem;
  background-color: rgb(255, 188, 199);
  color: #ff3333;
  border-radius: 100%;
  display: flex;
  transition: transform 0.3s;
}

.therapy-plan:hover {
  transform: translate(0px, -10px);
  cursor: pointer;
}

.therapy-plan img {
  width: 5rem;
  padding-bottom: .5rem;
}

@media screen and (max-width: 768px) {
  .therapy-wrapper {
    padding-bottom: 4rem;
  }

  .therapy-plans {
    flex-wrap: wrap;
    width: auto;
    /* justify-content: center; */
    width: 42rem;
    max-width: 80%;
  }

  .therapy-plan {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 612px) {
  .therapy-plans {
    justify-content: center;
    gap: 10px;
  }
}